import { Toaster } from "react-hot-toast";
import Minter from "./components/Minter";

function App() {
  return (
    <>
      <header>
          <a href="https://artgobblers.com/faq">FAQ</a>
          <a href="https://artgobblers.com/greenpaper">Green Paper</a>
      </header>
      <Minter />
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
