import { useContext } from "react";
import { DAppContext } from "../../context";
import "./index.css";

const Minter = () => {
  const { userData, mint, connectBrowserWallet } = useContext(DAppContext);

  return (
    <div className="connect-btn-container">
      {!userData ? (
        <button type="button" onClick={connectBrowserWallet} className="connect-wallet">
          Connect Wallet
        </button>
      ) : (
        <div className="minters">
          <button type="button" onClick={() => mint(1)} className="mint-btn">
            Mint 1
          </button>
          <button type="button" onClick={() => mint(2)} className="mint-btn">
            Mint 2
          </button>
          <button type="button" onClick={() => mint(5)} className="mint-btn">
            Mint 5
          </button>
        </div>
      )}
    </div>
  );
};

export default Minter;
